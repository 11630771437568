import './App.css';
import me from './images/me.jpg';

function Home() {
    return (
        <div id="content">
            <section id="index_section">
                <h1>Hey There, I'm Sergio</h1>
                <h4>I Am Currently A Student At Red River College Polytechnic</h4>
            </section>
            <aside>
                <img id="me" src={me} alt="Sergio Scaramuzzi."></img>
            </aside>
        </div>
    );
}

export default Home;