import './App.css';
import icon from './images/Icon.png';
import git from './images/git.png';
import linked from './images/LI-In.png';
import { Link, Routes } from 'react-router-dom';
import Home from './Home';
import { Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import About from './About';
import NavBar from './NavBar';
import Projects from './Projects';
import Contact from './Contact';

function App() {
  return (
    <main>
      <header>
        <Link to="/"><img id="Icon" src={icon} alt="Sergio Scaramuzzi Custom Logo."></img></Link>
        <NavBar />
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <footer>
        <div id="logos">
          <a href="https://github.com/keroserg"><img src={git} alt="Github logo."></img></a>
          <a href="https://www.linkedin.com/in/sergio-scaramuzzi-86aa191b6/"><img id="Li-in" src={linked} alt="linkedin logo."></img></a>
        </div>
        <NavBar />
      </footer>
    </main>
  );
}

export default App;
