import './App.css';
import proj1 from './videos/proj1.mp4';
import proj2 from './videos/proj2.mp4';

function Projects() {
    return (
        <div id="project_content">
            <section id="project_section">
                <div class="bulletlist">
                    <h2>C# Vehicle Sales Quote Calculator</h2>
                    <ul>
                        <li><h3>School project done with C# working on an application that made use of windows forms.</h3></li>
                        <li><h3>Application required creating data-binded properties and invoice forms.</h3></li>
                        <li><h3>Used a database full of vehicles that could be accessed and changed from the form itself.</h3></li>
                        <li><h3>Fixed all bugs found during user acceptance testing.</h3></li>
                        <li><h3>Refined code to provide better functionality when required.</h3></li>
                    </ul>
                </div>
                <div className='video'>
                    <iframe src="https://www.youtube.com/embed/tvX6-XnFrYQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="bulletlist">
                    <h2>Python Weather Graphing Application</h2>
                    <ul>
                        <li><h3>School project done with python, created a UI using wxpython where users could graph weather data.</h3></li>
                        <li><h3>Application required scraping to gather weather data from government website.</h3></li>
                        <li><h3>Created and utilized a database of the weather data that I parsed from the website.</h3></li>
                        <li><h3>Made sure all code scored an 8 or higher in regards to PEP8 standards.</h3></li>
                    </ul>
                </div>
                <div className='video'>
                    <iframe src="https://www.youtube.com/embed/t72AU2Lv62g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </section>
        </div>
    );
}

export default Projects;