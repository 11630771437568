import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFunctions } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyAn0rQt8iLXMIaj4hxRJAcIsCuvnjftQgY",

  authDomain: "website-e5977.firebaseapp.com",

  projectId: "website-e5977",

  storageBucket: "website-e5977.appspot.com",

  messagingSenderId: "535136631391",

  appId: "1:535136631391:web:e356a9d46a671a9895b9ea"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
