import './App.css';
import { useEffect } from "react";
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';

function Contact() {
    let functions;
    /*useEffect is an event listener, it will run the given function whenever the given variables change.
    In this case the variable is [], so it will only run once, on load.
    If we use a return in a useEffect it will only run the function in the return when the component is closed/deleted.*/
    useEffect(() => {
        functions = getFunctions();
        hideErrors();
        document.getElementById('success').style.display = 'none';
    }, [])

    function validate() {
        hideErrors();
        document.getElementById('success').style.display = 'none';
        formHasErrors();

        if (formHasErrors()) {
            return false;
        }

        return true;
    }

    function submit(e) {
        e.preventDefault();
        if (validate()) {
            const sendmail = httpsCallable(functions, 'sendMail')

            sendmail({
                name: document.getElementById('name').value,
                email: document.getElementById('email').value,
                subject: document.getElementById('subject').value,
                number: document.getElementById('number').value
            })
                .then(() => {
                    document.getElementById('success').style.display = 'block';
                    document.getElementById('contact_section').reset();
                    document.getElementById('success').innerText = 'Message was sent.';
                }).catch(() => {
                    document.getElementById('success').style.display = 'block';
                    document.getElementById('success').innerText = 'Message was not sent.';
                })

        }
    }

    /*
     * Raises a flag if a user input error is detected.
     */
    function formHasErrors() {
        let firstError = false;
        let errorFlag = false;
        let fields = ['name', 'number', 'email', 'subject']

        let regexDict =
        {
            email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
            number: /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
        }

        for (let i = 0; i < fields.length; i++) {
            if (document.getElementById(fields[i]).value === "") {
                document.getElementById(fields[i] + '_error').style.display = "block";
                errorFlag = true;
            }

            if (errorFlag === true && firstError === false) {
                document.getElementById(fields[i]).focus();
                firstError = true;
            }
        }

        for (const [key, value] of Object.entries(regexDict)) {
            let regex = new RegExp(value);
            let input = document.getElementById(key).value;

            if (!regex.test(input)) {
                if (document.getElementById(key + '_error').style.display === "none") {
                    errorFlag = true;
                    document.getElementById(key + "format_error").style.display = "block";
                }

                if (errorFlag === true && firstError === false) {
                    document.getElementById(key).focus();
                    firstError = true;
                }
            }
        }

        return errorFlag;
    }

    /*
     * Handles the reset event.
     */
    function resetForm() {
        hideErrors();
        document.getElementById("name").focus();
    }

    /*
     * Hides all errors.
     */
    function hideErrors() {
        let error = document.getElementsByClassName("error");

        for (let i = 0; i < error.length; i++) {
            error[i].style.display = "none";
        }
    }

    return (
        <div id="contact_content">
            <div class="myinfo">
                <h2>You Can Reach Me At:</h2>
            </div>
            <div class="myinfo">
                <a class="contactlinks" href="https://www.instagram.com/keroserg/?hl=en">Instagram</a>
                <a class="contactlinks" href="https://www.linkedin.com/in/sergio-scaramuzzi-86aa191b6/">Linkedin</a>
            </div>
            <form id="contact_section" onSubmit={(e) => submit(e)}>
                <h2>Got Any Feedback Or Questions?</h2>
                <label for="name">Name</label>
                <p class="error" id="name_error">* Required field</p>
                <input type="text" id="name" name="name" placeholder="Name.."></input>
                <label for="number">Phone Number</label>
                <p class="error" id="number_error">* Required field</p>
                <p class="error" id="numberformat_error">* Invalid phone number</p>
                <input type="tel" id="number" name="number" placeholder="Phone number.."></input>
                <label for="email">Email</label>
                <p class="error" id="email_error">* Required field</p>
                <p class="error" id="emailformat_error">* Invalid email</p>
                <input type="email" id="email" name="email" placeholder="email.."></input>
                <label for="subject">Subject</label>
                <p class="error" id="subject_error">* Required field</p>
                <textarea id="subject" name="subject" placeholder="Write something.."></textarea>
                <button type="submit" id="submit">Submit</button>
                <button type="reset" id="clear" onClick={() => resetForm()}>Clear</button>
                <p id='success'></p>
            </form >
        </div >
    );
}

export default Contact;