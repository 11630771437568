import './App.css';

function About() {
    return (
        <div id="about_content">
            <section id="about_section">
                <h2 class="heading">Hey, Nice To Meet Me!</h2>
                <p class="info">I am Sergio Scaramuzzi a second term BIT student at Red River College Polytechnic, I was born in 1995 and raised in Winnipeg, Manitoba and reside there still.
                    I'm just starting my career path in technology after changing paths from Criminology and Sociology, which I still share a passion with.
                    The best thing so far about working in the IT field is that I can create and express myself, like I hope I have done with this website!
                </p>
                <h2 class="heading">Hobbies & Passions</h2>
                <p class="info">I am enthusiastic about powerlifting and biodynamics and love to talk about it. I also love to play soccer and tennis although I dont get around to it very often.
                    I play alot of video games too, and occasionally stream for my friends to watch while we hang out and chat.
                    I have a knack and love for the dramatic arts, When I was in highschool I was part of the improv team and spent most of my time in the theater room.
                    When it comes to technology my current passion lies more in the programming part of computer science, I love learning new things and trying to apply them.
                </p>
                <h2 class="heading">What I Offer</h2>
                <ul id="skills">
                    <li>Application and UI development with Java, C# and Python</li>
                    <li>Expert verbal communication skills forged through the dramatic arts and years of service experience.</li>
                    <li>Knowledge and experience with querying and creating small to medium databases for use in programs.</li>
                    <li>Ability to create a functional and good looking webpage using a combination of PHP, HTML, CSS and JavaScript.</li>
                    <li>Perform subnetting on small networks and provide basic end and intermediary device security.</li>
                </ul>
            </section>
        </div>
    );
}

export default About;