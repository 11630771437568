import { Link } from "react-router-dom";

function NavBar() {

    return (
        <nav>
            <Link to="/">Home</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/contact">Contact Me</Link>
            <Link to="/about">About Me</Link>
        </nav>
    );
}

export default NavBar;